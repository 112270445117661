<template>
  <div class="about">
    <span class="file-box">
      Excel上传
      <input
        type="file"
        accept=".xls,.xlsx"
        class="upload-file"
        @change="Excel($event)"
      />
    </span>
    <div v-if="fill">
      <vxe-toolbar>
        <template #buttons>
          <vxe-button @click="exportEvent">导出.xlsx</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table border="inner" show-overflow :data="tableData" ref="xGrid2">
        <vxe-table-column
          field="name"
          title="料名称"
          width="120"
          fixed="left"
        ></vxe-table-column>
        <vxe-table-column
          v-for="(item, index) in tempalteObject"
          :key="index"
          :field="index"
          :title="index"
          width="60"
        ></vxe-table-column>
      </vxe-table>
    </div>
    <table
      border="1"
      cellspacing="0"
      cellpadding="0"
      style="display: none"
      id="tableBox"
    >
      <tbody>
        <tr>
          <td>料名称</td>
          <td v-for="(item, index) in tempalteObject" :key="index">
            {{ index }}
          </td>
        </tr>
        <tr v-for="(item, index) in lists" :key="index">
          <td>{{ index }}</td>
          <td v-for="(item2, index2) in item" :key="index2">
            {{ item2 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import XLSX from "xlsx";

export default {
  name: "Home",
  data() {
    return {
      lists: {},
      fill: false,
      tableData: [],
      tempalteObject: {
        1: null,
        8: 0,
        15: 0,
        22: 0,
        29: 0,
        36: 0,
        43: 0,
        50: 0,
        57: 0,
        64: 0,
        71: 0,
        78: 0,
        85: 0,
        92: 0,
        99: 0,
        106: 0,
        113: 0,
        120: 0,
        127: 0,
        134: 0,
        141: 0,
        148: 0,
        155: 0,
        162: 0,
        169: 0,
        176: 0,
        183: 0,
        190: 0,
        197: 0,
        204: 0,
        211: 0,
        218: 0,
        225: 0,
        232: 0,
        239: 0,
        246: 0,
        253: 0,
        260: 0,
        267: 0,
        274: 0,
        281: 0,
        288: 0,
        295: 0,
        302: 0,
        309: 0,
        316: 0,
        323: 0,
        330: 0,
        337: 0,
        344: 0,
        351: 0,
        358: 0,
        365: 0,
        372: 0,
      },
    };
  },
  methods: {
    getDay(d) {
      return (
        Math.ceil(
          (new Date(d) - new Date(new Date().getFullYear().toString())) /
            (24 * 60 * 60 * 1000)
        ) + 1
      );
    },
    exportEvent() {
      const workBook = XLSX.utils.table_to_book(
        document.getElementById("tableBox")
      );
      console.log(
        this.$refs.xGrid2.$el.querySelector(".body--wrapper>.vxe-table--body")
      );
      XLSX.writeFile(workBook, "数据导出.xlsx");
    },
    Excel(e) {
      let that = this;
      // 错误情况判断
      const files = e.target.files;
      if (files.length <= 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message({
          message: "上传格式不正确，请上传xls或者xlsx格式",
          type: "warning",
        });
        return false;
      } else {
        that.upload_file = files[0].name;
      }
      // 读取表格
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          // 读取第一张表
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
          // 打印 ws 就可以看到读取出的表格数据
          console.log(ws);
          // 定义一个新数组，存放处理后的表格数据
          that.lists = {};
          ws.forEach((item) => {
            that.lists[item["料名称"]] = JSON.parse(
              JSON.stringify(this.tempalteObject)
            );
            that.lists[item["料名称"]]["name"] = item["料名称"];
            for (const itemKey in item) {
              let localday = this.getDay(itemKey);
              if (localday > 0) {
                that.lists[item["料名称"]][Math.ceil(localday / 7) * 7 + 1] +=
                  item[itemKey];
              }
            }
          });
          // 调用方法将lists数组发送给后端
          this.submit_form(that.lists);
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    submit_form(data) {
      this.fill = true;
      for (const dataKey in data) {
        this.tableData.push(data[dataKey]);
      }
      console.log(data);
      // 在这里发送数据
    },
  },
};
</script>
<style>
.file-box {
  position: relative;
  display: block;
  border: none;
  border-radius: 4px;
  background-color: #409eff;
  height: 35px;
  width: 98px;
  text-align: center;
  line-height: 35px;
  *zoom: 1;
  color: #ffffff;
}

.upload-file {
  font-size: 20px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  filter: alpha(opacity=0);
  width: 100%;
}
</style>
